<template>
  <b-overlay :show='isLoading' rounded='lg' opacity='0.6' spinner-variant='primary'>
		<template #overlay>
			<div class="d-flex align-items-center">
				<b-spinner small type="grow" variant="secondary"></b-spinner>
				<b-spinner type="grow" variant="dark"></b-spinner>
				<b-spinner small type="grow" variant="secondary"></b-spinner>
			</div>
		</template>
		<b-row class="mt-4 create-new">
			<b-col cols="12">
				<router-link class="btn btn-sm btn-default" :to="{name: IosTierManagePath.name}">
					<em class="fa fa-backward"></em> Manage Ios Tier
				</router-link>
			</b-col>
			<b-col>
        <b-form @submit='onSubmit' @reset='onReset'>
					<div class="card mt-4">
						<div class="bg-success p-3">
							<h5 class="card-title mb-0 font-weight-bold">
								Create New Ios Tier
							</h5>
						</div>
						<div class="card-body">
							<b-row>
								<b-col lg="6" class="mt-4">
									<b-card class="bg-info text-dark">(*) Kolom berlabel bintang wajib diisi.</b-card>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Tiercode *">
										<b-form-input placeholder="example .c.usd.3.99" id="tier_code" v-model="form.tier_code" type="text" required></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Price IDR *">
										<b-form-input id="price_idr" v-model.number="form.price_idr" type="number" step="any" placeholder="example 1000" required></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Tier Price *">
										<b-form-input id="tier_price" v-model.number="form.tier_price" type="number" step="any" placeholder="example 1.99" required></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Tier Type *">
										<b-form-select
											id="tier_type"
											track-by="value"
											v-model="form.tier_type"
											:options="tierTypeOptions"
											required
										>
										</b-form-select>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Tiercode Currency *">
										<multiselect
											v-model="form.currency"
											label="iso4217_code"
											track-by="id"
											placeholder="Tiercode Currency"
											open-direction="bottom"
											:options="tiercodeCurrencySearchOptions"
											:searchable="true"
											:loading="isTiercodeCurrencySearch"
											:close-on-select="true"
											:clear-on-select="false"
											:preserve-search="false"
											:preselect-first="false"
											:multiple="false"
											:taggable="false"
											@search-change="tiercodeCurrencyFind"
											required
										>
										</multiselect>
									</b-form-group>
								</b-col>
							</b-row>
							<hr />
							<b-row>
								<b-col>
									<b-form-group>
										<b-form-checkbox v-model="form.is_active" :value="true" :unchecked-value="false">
											Is Active
										</b-form-checkbox>
									</b-form-group>
								</b-col>
							</b-row>
							<br />
							<b-row>
								<b-col>
									<b-button variant="primary" type="submit">create</b-button>
								</b-col>
							</b-row>
						</div>
					</div>
				</b-form>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { IosTierManagePath } from "../../router/marketing";
import constant from "../../store/constant";

export default {
	name: "new-ios-tier",
	data() {
		return {
			debounce: null,
			IosTierManagePath,
			form: {
				tier_code: null,
				price_idr: null,
				tier_price: null,
				tier_type: null,
				currency: null,
				is_active: false
			},
			isTiercodeCurrencySearch: false,
			tiercodeCurrencySearchOptions: [],
			tierTypeOptions: constant.TIER_TYPE_OPTION,
		};
	},
	computed: {
		...mapState({
			isError: (state) => state.tiercodeIOS.isError,
			isLoading: (state) => state.tiercodeIOS.isLoading,
			errorMessage: (state) => state.tiercodeIOS.errorMessage,
			successMessage: (state) => state.tiercodeIOS.successMessage,
			item: (state) => state.tiercodeIOS.item
		})
	},
	watch: {
		errorMessage: function() {
			if (!this.errorMessage) return;
			this.messageAlert("error", this.errorMessage, 5000);
		},
		successMessage: function() {
			if (!this.successMessage) return;
			this.messageAlert("success", this.successMessage);

			Object.assign(this.$data, this.$options.data.call(this));
			this.$router.push(IosTierManagePath);
		}
	},
	created() {
		this.tiercodeCurrencyFetch();
	},
	methods: {
		...mapActions("tiercodeIOS", ["postIosTiers"]),
		...mapActions("currency", ["AutocompleteSearch", "fetchCurrency"]),

		onSubmit(event) {
			event.preventDefault();
			const data = this.form;
			const payload = {
				tier_code: data.tier_code,
				price_idr: data.price_idr,
				tier_price: data.tier_price,
				tier_type: data.tier_type,
				currency_id: data.currency?.id ? data.currency.id : 0,
				is_active: data.is_active ? true : false,
			};
			this.postIosTiers(payload);
		},
		onReset() {},
		tiercodeCurrencyFetch() {
			this.isTiercodeCurrencySearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.fetchCurrency({ page: 1, limit: 100 })
					.then((response) => {
						this.tiercodeCurrencySearchOptions = response.data.data.rows;
						this.isTiercodeCurrencySearch = false;
					})
					.catch(() => {
						this.isTiercodeCurrencySearch = false;
					});
			}, 1200);
		},
		tiercodeCurrencyFind(query) {
			if (!query) return;

			this.isTiercodeCurrencySearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.AutocompleteSearch({ q: query })
					.then((response) => {
						this.tiercodeCurrencySearchOptions = response.data.data.rows;
						this.isTiercodeCurrencySearch = false;
					})
					.catch(() => {
						this.isTiercodeCurrencySearch = false;
					});
			}, 1200);
		},
		messageAlert(icon, title, timer = 3000) {
			this.$swal({
				toast: "true",
				position: "top-end",
				icon,
				title,
				showConfirmButton: false,
				timer,
				timerProgressBar: true,
			});
		},
	},
	filters: {
		toCurrencyID: function(val) {
			return parseInt(val).toLocaleString("id");
		},
	},
};
</script>
